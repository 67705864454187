import styled from 'styled-components';

export const ClinicContentWrapper = styled.div`
  margin: 20px;
  padding: 0 20px;
  background-color: ${({ theme }) => theme.color.darkGrey};
  border-radius: 7px;
  min-height: calc(100vh - 117px);
`;

export const ClinicTitle = styled.h2`
  text-align: center;
  margin: 0 0 30px 0;
  padding: 20px 0 0 0;
  color: ${({ theme }) => theme.color.primaryLight};
`;

export const ClinicDescriptionParagraph = styled.p`
  color: ${({ theme }) => theme.color.primaryLight};
  padding-bottom: 20px;
`;
