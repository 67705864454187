import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlaceholderBlock } from '../../components';

const About: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PlaceholderBlock>
      <h1>{t('about')}</h1>
    </PlaceholderBlock>
  );
};

export default About;
