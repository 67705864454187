import React from 'react';
import { useQuery } from 'react-apollo-hooks';
import { ClinicContentWrapper, ClinicDescriptionParagraph, ClinicTitle } from './Styled';

import { Loading } from '../../components';
import { GET_CLINIC } from '../../graphql/queries';
import { GetClinicDetail, GetClinicDetailVariables } from '../../graphql/types';

const ClinicDetail: React.FC<GetClinicDetailVariables> = ({ id }) => {
  const { data, loading, error } = useQuery<GetClinicDetail, GetClinicDetailVariables>(GET_CLINIC, {
    variables: { id },
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <div>Something went wrong</div>;
  }
  if (!data) {
    return <></>;
  }

  const { name, description } = data.clinic;

  return (
    <ClinicContentWrapper>
      <ClinicTitle>{name}</ClinicTitle>
      {description.split('\n').map((paragraph, index) => (
        <ClinicDescriptionParagraph key={index}>{paragraph}</ClinicDescriptionParagraph>
      ))}
    </ClinicContentWrapper>
  );
};

export default ClinicDetail;
