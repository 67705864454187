import React from 'react';
import { useQuery } from 'react-apollo-hooks';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../components';
import { GET_CLINICS } from '../../graphql/queries';
import { GetClinicList } from '../../graphql/types';
import routes from '../../routes';
import {
  ClinicLink,
  ClinicListHeading,
  ClinicListItem,
  ClinicListUl,
  ClinicListWrapper,
} from './Styled';

const ClinicList: React.FC = () => {
  const { t } = useTranslation();
  const { data, loading, error } = useQuery<GetClinicList>(GET_CLINICS);
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <div>Something went wrong</div>;
  }
  if (!data) {
    return <></>;
  }

  return (
    <ClinicListWrapper>
      <ClinicListHeading>{t('clinic-list')}</ClinicListHeading>
      <ClinicListUl>
        {data.clinics.map(clinic => (
          <ClinicListItem key={clinic.id}>
            <ClinicLink to={routes.clinicDetail.replace(':id', clinic.id.toString())}>
              {clinic.name}
            </ClinicLink>
          </ClinicListItem>
        ))}
      </ClinicListUl>
    </ClinicListWrapper>
  );
};

export default ClinicList;
