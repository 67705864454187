import styled from 'styled-components';

export const PlaceholderBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 117px);
  margin: 20px;
  background-color: ${({ theme }) => theme.color.darkGrey};
  color: ${({ theme }) => theme.color.primaryLight};
  border-radius: 7px;
`;
