import { Link } from '@reach/router';
import React from 'react';
import styled from 'styled-components';

export const ClinicListWrapper = styled.div`
  margin: 20px;
  background-color: ${({ theme }) => theme.color.darkGrey};
  border-radius: 7px;
  min-height: calc(100vh - 117px);
`;

export const ClinicListHeading = styled.h1`
  color: ${({ theme }) => theme.color.primaryLight};
  text-align: center;
  margin: 0;
  padding: 10px 0;
`;

export const ClinicListUl = styled.ul`
  list-style-type: none;
  border-top: 2px solid ${({ theme }) => theme.color.primaryLight};
  margin: 0;
  padding: 0;
`;

export const ClinicListItem = styled.li`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.color.primaryInverse};
`;

export const ClinicLink = styled(props => <Link {...props} />)`
  width: 100%;
  padding: 20px;
  font-weight: bold;
  font-size: 18px;
  color: ${({ theme }) => theme.color.white};
  text-decoration: none;
  transition: color 150ms ease-out;
  &:hover {
    color: ${({ theme }) => theme.color.backgroundPrimary};
  }
`;
