import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import routes from '../../routes';
import { NavigationWrapper, NavLink } from './Styled';

const Navigation: React.FC<RouteComponentProps> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <>
      <NavigationWrapper>
        <NavLink to={routes.home}>{t('home')}</NavLink>
        <NavLink to={routes.clinicList}>{t('clinics')}</NavLink>
        <NavLink to={routes.about}>{t('about')}</NavLink>
      </NavigationWrapper>
      <main>{children || null}</main>
    </>
  );
};

export default Navigation;
