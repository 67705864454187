import React from 'react';
import { useTranslation } from 'react-i18next';
import { reactLogo } from '../../assets/images';
import { PlaceholderBlock } from '../../components';
import { RotatingImage } from './Styled';

const Home: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PlaceholderBlock>
      <h1>{t('home')}</h1>
      <RotatingImage src={reactLogo} />
    </PlaceholderBlock>
  );
};

export default Home;
