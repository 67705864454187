import gql from 'graphql-tag';

export const GET_CLINICS = gql`
  query {
    clinics: dental_office(limit: 5) {
      id: dental_office_id
      name: dental_office_name
    }
  }
`;

export const GET_CLINIC = gql`
  query($id: Int!) {
    clinic: dental_office_by_pk(dental_office_id: $id) {
      id: dental_office_id
      name: dental_office_name
      description: dental_office_description
    }
  }
`;
