import { RouteComponentProps, Router } from '@reach/router';
import React from 'react';
import { Navigation } from './components';
import { About, ClinicDetail, ClinicList, Home } from './pages';
import routes from './routes';
import { RouteParams } from './types';

const { home, about, clinicList, clinicDetail } = routes;

const Route: React.FC<{ component: React.FC<RouteParams> } & RouteComponentProps> = ({
  component: Component,
  ...rest
}) => <Component {...rest} />;

const App = () => (
  <Router>
    <Navigation path={home}>
      <Route component={Home} path={home} />
      <Route component={About} path={about} />
      <Route component={ClinicList} path={clinicList} />
      <Route component={ClinicDetail} path={clinicDetail} />
    </Navigation>
  </Router>
);

export default App;
