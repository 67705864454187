import { Link } from '@reach/router';
import React from 'react';
import styled from 'styled-components';

export const NavigationWrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.primary};
  height: 70px;
  border-bottom: 7px double ${({ theme }) => theme.color.primaryInverse};
`;

export const NavLink = styled(props => <Link {...props} />)`
  color: ${({ theme }) => theme.color.white};
  font-weight: bold;
  font-size: ${({ theme }) => theme.font.size.large};
  line-height: 70px;
  text-decoration: none;
  transition: color 150ms ease-out;
  width: 100%;
  text-align: center;
  &:hover {
    color: ${({ theme }) => theme.color.grey};
  }
  &[aria-current] {
    color: ${({ theme }) => theme.color.darkGrey};
  }
`;
