import React, { useEffect, useState } from 'react';
import { loading } from '../../assets/images';
import { LoadingContainer, LoadingImage } from './Styled';

interface Props {
  delay?: number;
}

interface State {
  shouldShowLoader: boolean;
}

const Loading: React.FC<Props> = ({ delay = 200 }) => {
  const [shouldShowLoader, setShouldShowLoader] = useState<State>();
  useEffect(() => {
    const timer = setTimeout(() => setShouldShowLoader({ shouldShowLoader: true }), delay);

    return () => clearTimeout(timer);
  }, [delay]);

  return shouldShowLoader ? (
    <LoadingContainer>
      <LoadingImage src={loading} />
    </LoadingContainer>
  ) : (
    <></>
  );
};

export default Loading;
