import { createGlobalStyle } from 'styled-components';

export const theme = {
  color: {
    primary: '#A68965',
    primaryLight: '#F2EAE4',
    primaryInverse: '#403333',
    backgroundPrimary: '#A6A4A5',
    white: '#f7f7f7',
    grey: '#ccc',
    darkGrey: '#222',
  },
  font: {
    size: {
      tiny: '10px',
      small: '12px',
      medium: '14px',
      larger: '16px',
      large: '20px',
      extraLarge: '24px',
    },
  },
};

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: ${theme.color.backgroundPrimary};
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
`;
